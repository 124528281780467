@import '../../styles/colors';


.ps-sidebar-container {
  background-color: $color-white !important;
}
.ps-menuitem-root.ps-active {
  background-color: $color-gray-1 !important
}


.ps-submenu-content {
  width: 300px !important;
}