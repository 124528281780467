@import '../src/styles/colors';


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.page-header {
  h1 {
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
  }
  padding: 10px 0;
  p {
    margin-bottom: 0;
  }
}


.IconBox {
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  color: $color-white;
  font-size: 16px;

  &--purple {
    background-color: $color-purple;
  }
  &--yellow {
    background-color: $color-yellow;
  }
  &--green {
    background-color: $color-green;
  }
  &--green-dark {
    background-color: $color-green-dark;
  }
  &--orange {
    background-color: $color-orange;
  }
  &--red {
    background-color: $color-red;
  }
  &--blue {
    background-color: $color-navy-blue;
  }
}
