@import '../../../../src/styles/colors';

.VoohForm {
  &__droppable-table {
    table-layout: fixed;
  }

}

.dnd-copy ~ .MuiTableRow-root {
  transform: none !important;
}


.mediabank-draggable-list {
  tr:not(.dragging) {
    transform: none !important;
  }

  tr:empty {
    display: none !important;
  }

  .dragging-multiple::after {
    content: attr(data-content);
    position: absolute;
    right: -10px;
    width: 22px;
    height: 22px;
    text-align: center;
    color: white;
    top: -20px;
    border-radius: 50%;
    background: $color-blue;
    border: 2px solid $color-blue;
  }
}

.react-checkbox-tree {
  //height: 200px;
  max-height: 400px;
  overflow-y: auto;
}