@import '../../styles/colors';
@import '../../styles/constants';
@import '../../styles/mixins';

.CmTopBar {
  display: flex;
  position: sticky;
  top: 0;
  height: $topbar-height;
  border-bottom: 1px solid $color-gray-2;
  background-color: $color-white;


  &__logo {
    margin-left: 58px;
    align-self: center;

    &--img {
      height: 48px;
    }
  }
}