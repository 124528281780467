.CreationPreview__container {
  position: relative;

  iframe {
    transform-origin: top left;
    border: 100px solid black;
    border-radius: 70px;
    position: fixed;
    top: 0;
    left: 0;
  }
}
