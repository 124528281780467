@import '../../../../src/styles/colors';

.CreationStatus {
  &--draft {
    background: $color-gray-2;
  }
  &--published {
    background: $color-green-light;
  }
  &--planned {
    background: $color-yellow-light;
  }
  &--archived {
    background: $color-red-light;
  }

}