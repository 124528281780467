@import '../../../../src/styles/colors';


.DraftsList {
  &__header {
    h1 {
      margin-bottom: 0;
    }
    padding: 10px 0;
    p {
      margin-bottom: 0;
    }
  }
  table {
    button.MuiButtonBase-root.MuiButton-root.MuiButton-text  {
      max-width: 100px;
      text-transform: none;
    }
  }
  .MuiGrid-item {
    font-size: 10px !important;
  }
  .uncompleted {
    td,th {
      color: $color-gray-4;
    }
  }
}

